import React from 'react'
import { animated, useTrail, easings } from 'react-spring'

import { _Trail } from 'types/types'

const spring = {
  trailText: {
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 100 },
    delay: 600, //400
    config: {
      easing: easings.easeOutQuad,
      duration: 800, //800
    },
  },
}

export const Trails: React.FC<_Trail> = ({ classname, trail, children }) => {
  const items = React.Children.toArray(children)

  return (
    <div className={classname}>
      {trail.map(({ ...style }: any, index: number) => (
        <animated.div key={index} style={style}>
          {items[index]}
        </animated.div>
      ))}
    </div>
  )
}

export const useAnimation = () => {
  const trailText = useTrail(3, {
    ...spring.trailText,
  })

  return {
    Trails,
    trailText,
  }
}
