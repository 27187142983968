import React from 'react'

import Layout from 'shared/Layout'
import Error from 'main/Error'

const ErrorPage = () => {
  return (
    <Layout backgrounds="transparent">
      <Error />
    </Layout>
  )
}

export default ErrorPage
