import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { _Datas } from 'types/types'

import { useAnimation } from 'main/Error/Animation'
import Seo from 'shared/Seo'
import { LinkButton } from 'shared/Button'

const s = {
  container: `w-full h-screen`,
  wrapper: `max-w-screen-wrapper w-11/12 mx-auto`,

  content: `absolute top-2/4 -translate-y-2/4 z-[2] w-[55rem] h-fit text-white 
  smx:w-[50rem] smx:mt-8 xsx:w-[45rem] xsx:mt-0 2xsx:w-[35rem] 2xsx:mt-8 3xsx:w-[35rem] 3xsx:mt-0`,
  group: `w-full h-full`,
  title: `-mt-4 text-error`,
  subtitle: `-mt-8 mb-20 text-clamp-2xl text-black xsx:text-2xl 2xsx:w-[32rem] 2xsx:-mt-6`,

  image: `absolute top-0 w-full h-full object-cover text-transparent`,
  overlay: `absolute top-0 left-0 z-[1] w-full h-full bg-opblack`,
}

const NotFoundPage = () => {
  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        error: getError {
          slug
          title
          subtitle
          link
          text
          image {
            title
            path
          }
        }
      }
    }
  `)

  const { Trails, trailText } = useAnimation()

  const { title, subtitle, link, text, image }: _Datas = takeshape.error

  return (
    <section className={s.container}>
      <Seo title={title} description={subtitle} />
      <img
        className={s.image}
        src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
        alt={image.title}
      />
      <div className={s.overlay} />
      <div className={`${s.wrapper}`}>
        <div className={s.content}>
          <Trails classname={s.group} trail={trailText}>
            <h1 className={s.title}>{title}</h1>

            <p className={s.subtitle}>{subtitle}</p>

            <LinkButton to={`${link}`}>{text}</LinkButton>
          </Trails>
        </div>
      </div>
    </section>
  )
}

export default NotFoundPage
